
<template>
	<div class="container">
		<div class="row">
			<div class="col-md-4 col-sm-12 text-wrapper">
				<svg-zmudri-logo />
				<p class="fw-3 mt-4"><b class="z-fw-6">Zmudri, o.z.</b>, IČO: 52033589</p>
				<p class="fw-3"><b class="z-fw-6">Copyright © 2018-{{ currentYear }} Zmudri.sk</b></p>
			</div>
			<div class="col-md-2 col-sm-12 mt-3 mt-md-0 text-wrapper">
				<router-link to="/o-nas">{{ $t('O nás') }}</router-link>
				<router-link to="/kurzy">{{ $t('Naše kurzy') }}</router-link>
				<router-link :to="$route.path == '/pre-ucitelov' ? '/' : '/pre-ucitelov'">
					{{ $route.path == '/pre-ucitelov' ? $t('Pre študentov') : $t('Pre učiteľov') }}
				</router-link>
			</div>
			<div class="col-md-3 col-sm-12 mt-3 mt-md-0 text-wrapper">
				<a href="https://zmudri.teachable.com/p/terms" target="_blank" rel="noopener noreferrer">{{ $t('Podmienky používania') }}</a>
				<a href="https://docs.google.com/document/d/1MfD458KtyvPbU9ifGu0lnSAFUsOcNr4z/edit?usp=sharing&ouid=101522684688141446307&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer">{{ $t('Spracúvanie osobných údajov') }}</a>
				<a href="https://docs.google.com/document/d/1RfRFlz4UAZgXEGnpqoMqugRU_gCp12jG/edit?usp=sharing&ouid=101522684688141446307&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer">Informácie o cookies</a>
				<a @click="openCookie()">Súhlas s cookies</a>
			</div>
			<div class="col-md-3 col-sm-12 mt-3 mt-md-0 text-wrapper">
				<p class="fw-3"><b class="z-fw-6">{{ $t('Sociálne siete') }}</b></p>
				<a class="fw-3" href="https://www.instagram.com/zmudri.sk/" target="_blank" rel="noopener noreferrer">
					<b-icon-instagram class="mr-2" />Instagram
				</a>
				<a class="fw-3" href="https://www.facebook.com/zmudri.sk/" target="_blank" rel="noopener noreferrer">
					<b-icon-facebook class="mr-2" />Facebook
				</a>
				<a class="fw-3" href="https://www.linkedin.com/company/13019303/admin/" target="_blank" rel="noopener noreferrer">
					<b-icon-linkedin class="mr-2" />LinkedIn
				</a>
				<a class="fw-3" href="mailto:team@zmudri.sk">
					<b-icon-envelope class="mr-2" />team@zmudri.sk
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { BIconInstagram, BIconFacebook, BIconLinkedin, BIconEnvelope } from 'bootstrap-vue'
import moment from 'moment-timezone'
import cookie from '@/plugins/appzmudri@component/cookie-banner/getCookie'

export default {
	components: {
		BIconInstagram,
		BIconFacebook,
		BIconLinkedin,
		BIconEnvelope,
		'svg-zmudri-logo': () => import('./icon/zmudri-logo.svg?inline')
	},

	computed: {
		currentYear() {
			return moment().format('YYYY')
		}
	},
	methods: {
		openCookie() {
			this.$root.$emit('reopen')
			this.$root.$emit('bv::toggle::collapse', 'cookie-consent-sidebar')
		},
	}
}
</script>

<style lang="scss" scoped>
.container {
	position: relative;
	padding: 75px 0;
	border-top: 1px solid var(--a-color-blue-light);
	bottom: 0px;
	left: 20px;
	width: 100vw;
}

.text-wrapper {
	p, a {
		display: block;
		margin: 0;
		color: #322D1E;
		font-size: 13px;
		line-height: 27px;
		b {
			color: #000000;
		}
	}

	a {
		line-height: 2.5;
		text-decoration: none;
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}
}
</style>
